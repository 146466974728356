










































import NotificationPopupContentMixin from '@/components/notification-popup/NotificationPopupContentMixin';
import ShareIcon from '@/assets/images/icons/share-box-icon.svg?inline';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import InviteUserInput from '@/components/invite-user/InviteUserInput.vue';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';

import { ERole } from '@/api/schema';
import { textOverflowMiddle } from '@/helpers/formatString';

export default {
  name: 'NotificationPopupSuccessOnboarding',
  mixins: [NotificationPopupContentMixin],
  components: { ShareIcon, InviteUserInput, CodeBlock },
  data(): any {
    return {
      email: '',
      type: ERole.ROLE_OPERATOR_MARKETER
    };
  },
  computed: {
    ...mapState('Onboarding', ['inviteLinks', 'operators']),
    ...mapGetters('Onboarding', ['currentAccount']),
    currentLink(): string {
      const invite = this.inviteLinks.find(({ role }) => role === this.type);

      return invite?.link;
    },
    computedLink(): string {
      return textOverflowMiddle(this.currentLink, 30, 30);
    }
  },
  methods: {
    ...mapMutations('Onboarding', ['setSelectedOperator']),
    ...mapActions('Onboarding', ['getInviteLinks']),
    handleLink(): void {
      this.closePopup();
      setTimeout(() => this.$router.push({ path: 'loyalty-promotion' }), 300);
    },
    closePopup(): void {
      const operator = this.currentAccount.children.find(({ test }) => !test);

      if (operator) {
        this.setSelectedOperator(operator);
      }

      this.handleClose();
    }
  },

  created(): void {
    this.getInviteLinks();
  }
};
